<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon>
        <v-btn title="voltar" icon @click="$router.go(-1)">
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>

      <v-card-title> Detalhes da cobrança </v-card-title>
    </v-toolbar>

    <div v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div class="text-center pa-4">Carregando dados da cobrança...</div>
    </div>

    <div class="pa-4" v-else>
      <h3 class="mt-4">Detalhes da cobrança N. {{ charge.id }}</h3>

      <div class="charge-details mt-4">
        <div>
          <strong>Anunciante: </strong>
          {{
            `${charge.Advertiser.User.firstName} ${charge.Advertiser.User.lastName}`
          }}
        </div>
        <div><strong>Empresa:</strong> {{ charge.Company.companyName }}</div>
        <div>
          <strong>Data de vencimento:</strong>
          {{ $moment(charge.dueDate).format("DD/MM/YYYY") }}
        </div>
        <div>
          <strong>Status: </strong>
          <v-chip x-small :color="statusColor(charge.status)">
            {{ statusName(charge.status) }}
          </v-chip>
        </div>
        <div><strong>Valor total:</strong> {{ chargeValue }}</div>
      </div>

      <h4 class="mt-4">Itens da cobrança</h4>

      <div class="charge-items mt-4">
        <v-data-table
          :headers="tableHeaders"
          :items="charge.Announcements"
          disable-pagination
          hide-default-footer
          class="elevation-1"
        ></v-data-table>
      </div>
    </div>
  </v-card>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  components: {},
  data: () => ({
    loading: false,
    tab: null,
    charge: null,
    tableHeaders: [
      {
        text: "#ID",
        value: "id",
      },
      {
        text: "Plano",
        value: "Plan.name",
        sortable: false,
      },
      {
        text: "Período de pagamento",
        value: "TimeIntervalForPayment.name",
        sortable: false,
      },
      {
        text: "Categoria anunciada",
        value: "AdCategory.name",
        sortable: false,
      },
    ],
  }),
  methods: {
    async init() {
      try {
        this.loading = true;

        this.charge = await this.loadCharge();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadCharge() {
      const chargeId = this.$route.params.id;

      const response = await this.$axios.get(`/charges/${chargeId}`);

      return response.data;
    },
    statusColor(status) {
      switch (status) {
        case "pending":
          return "warning";

        case "paid":
          return "success";

        case "processing-payment":
          return "info";

        case "payment-error":
          return "error";

        case "canceled":
          return "gray";

        default:
          return "white";
      }
    },
    statusName(status) {
      switch (status) {
        case "pending":
          return "Pendente";

        case "paid":
          return "Pago";

        case "canceled":
          return "Cancelado";

        case "processing-payment":
          return "Pagamento em processamento";

        case "payment-error":
          return "Erro no pagamento";

        default:
          return "Status desconhecido";
      }
    },
  },
  computed: {
    chargeValue() {
      return parseFloat(this.charge.value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    paymentMethodsVisibility() {
      return (
        this.charge.status === "payment-error" ||
        this.charge.status === "pending"
      );
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.checkout-summary {
  background-color: rgb(224, 224, 224);
}
</style>